import React, {useState, useEffect} from "react"
import { graphql } from 'gatsby'
import Shell from '../components/global/shell'
import Container from '../components/global/container'
import Helmet from "react-helmet"
import styled from 'styled-components'
import tw from "twin.macro"
import Beer from '../images/buymeabeer.png'

import must from '../images/aesthetics/must.png'
import can from '../images/aesthetics/can.png'
import cannot from '../images/aesthetics/cannot.png'

const InnerContainer = styled.div`
  input:focus {outline:none;}
`;

const InnerContainerTable = styled.div`
  overflow-x: scroll;
  ${tw`mt-10 mb-20`};
`

const Table = styled.table`
  overflow-x: scroll;
  overflow-y: scroll;
  table-layout: fixed;
  width: auto;  
  ${tw``};


  thead {
    vertical-align: middle;
    
    tr {
      th {
        ${tw`pb-3`}
        position: sticky;
        top: 0;
        z-index: 40;
        width: 40px;
      }
    }
  }

  tbody {
    tr {
      height: 20px;

     .off {
        min-width: 150px;
        ${tw`text-xs md:text-sm`}

        @media (min-width: 1024px) {
          min-width: 200px;
        }
      }

      td {
        padding: 0;
      }

      td:not(.off)  {
        position: relative;
        min-width: 30px;
        
        @media (min-width: 1024px) {
          min-width: 60px;
        }
        
        &:before {
          content:"";
          position: absolute;
          z-index: -1;
          top: 0;
          bottom: 0;
          left: 50%;
          border-left: 1px solid #ccc;
          transform: translate(50%);
        }

        hr {
          position: absolute;
          top: 50%;
          z-index: -2;
          left: 0;
          width: 100%;
        }
      }

    }

    tr > :first-child {
      position: sticky;
      left: 0;
      z-index: 90;
    }
  }
`

const createCell = function(text) {

  if (text === "NA") {
    return("")
  } else if (text === "3") {
    return <img src={must} className='h-4 inline' alt="Must be mapped" />
  } else if (text === "2") {
    return <img src={can} className='h-4 inline' alt="Can be mapped" />
  }

  return <img src={cannot} className='h-4 inline' alt="Cannot be mapped" />
}


const Aesthetics = (props) => {

  const geoms = props.data.geoms.nodes;

  const [inputValue, setInputValue] = useState('');
  const [currentData, setCurrentData] = useState(geoms);
  const geomNull = {group: 0, color: 0, size: 0, linetype: 0, alpha: 0,
    fill: 0, x: 0, y: 0, xmin: 0, xmax: 0, ymin: 0, ymax: 0, yend: 0,
    xend: 0, weight: 0, shape: 0, stroke: 0, width: 0, 
    height: 0, angle: 0, radius: 0, hjust: 0, vjust: 0, label: 0,
    family: 0, fontface: 0, lineheight: 0}
  const [currentGeoms, setCurrentGeoms] = useState(geomNull);

  useEffect(() => {
    if (inputValue !== '') {
      const filtered = geoms.filter((g, i) => {
        return(g.geometric_object.includes(inputValue));
      })

      // In order to reduce the columns of the table, we need to know
      // which geometric objects do not occur at all in the filtered list
      let geomIndex = {group: 0, color: 0, size: 0, linetype: 0, alpha: 0,
                         fill: 0, x: 0, y: 0, xmin: 0, xmax: 0, ymin: 0, ymax: 0, yend: 0,
                         xend: 0, weight: 0, shape: 0, stroke: 0, width: 0, 
                         height: 0, angle: 0, radius: 0, hjust: 0, vjust: 0, label: 0,
                         family: 0, fontface: 0, lineheight: 0}

      for (const [key, value] of Object.entries(filtered)) {
        for (const [secondKey, geom] of Object.entries(value)) {
          geomIndex[secondKey] = geom == "NA" ? geomIndex[secondKey] + 1 :  geomIndex[secondKey];
        }
      }


      setCurrentGeoms(geomIndex);
      setCurrentData(filtered);
    } else {
      setCurrentGeoms(geomNull);
      setCurrentData(geoms);
    }
  }, [inputValue]);

  
  return (
    <Shell>
      <Helmet>
        <title>Find your aesthetics</title>
      </Helmet>
      
      <Container className='container'>
        <InnerContainer className='mt-10 flex flex-col md:flex-row md:justify-between items-end h-full'>
          <div className='w-full: md:w-2/5'>
            <h1 className='text-xl md:text-3xl font-bold mb-2'>Aesthetics finder</h1>
            <p className='text-base md:text-lg text-gray-700 md:leading-relaxed'>Type in your geom and find the right aesthetics for your visualization.</p>

            <div className='py-4 mt-5'>
              <span className='text-base md:text-2xl p-2 pr-0'>geom_</span><input onChange={(i) => setInputValue(i.currentTarget.value)} autoFocus className='text-base md:text-2xl p-2 border  border-gray-300 rounded' type='text' />
            </div>
          </div>

          <div className='rounded flex flex-col p-4 shadow bg-white'>
            <div className='flex flex-row my-1'><img src={must} className='h-3 md:h-5 inline mr-3' alt="Must be mapped" /><p className='text-xs md:text-base'>Aesthetics that <span className='font-bold'>must be mapped</span> to the data</p></div>
            <div className='flex flex-row my-1'><img src={can} className='h-3 md:h-5 inline mr-3' alt="Can be mapped" /><p className='text-xs md:text-base'>Aesthetics that <span className='font-bold'>can be mapped</span> to the data</p></div>
            <div className='flex flex-row my-1'><img src={cannot} className='h-3 md:h-5 inline mr-3' alt="Cannot be mapped" /><p className='text-xs md:text-base'>Aesthetics that <span className='font-bold'>should be set</span></p></div>
          </div>

          <a href="https://www.buymeacoffee.com/ggplot2tor" target="_blank" without rel="noreferrer" className='w-32 md:w-40 self-start mt-5 md:mt-0'><img className='rounded' src={Beer} /></a>
        </InnerContainer>
      </Container>
        
      <Container>
        <InnerContainerTable className='h-96'>
          <Table>
            <thead>
              <tr>
                <th className='text-xs'></th>
                {currentGeoms.x === currentData.length ? null : <th className='text-xs'>x</th>}
                {currentGeoms.y === currentData.length ? null : <th className='text-xs'>y</th>}
                {currentGeoms.xmin === currentData.length ? null : <th className='text-xs'>xmin</th>}
                {currentGeoms.xmax === currentData.length ? null : <th className='text-xs'>xmax</th>}
                {currentGeoms.ymin === currentData.length ? null : <th className='text-xs'>ymin</th>}
                {currentGeoms.ymax === currentData.length ? null : <th className='text-xs'>ymax</th>}
                {currentGeoms.xend === currentData.length ? null : <th className='text-xs'>xend</th>}
                {currentGeoms.yend === currentData.length ? null : <th className='text-xs'>yend</th>}
                {currentGeoms.color === currentData.length ? null : <th className='text-xs'>color</th>}
                {currentGeoms.size === currentData.length ? null : <th className='text-xs'>size</th>}
                {currentGeoms.linetype === currentData.length ? null : <th className='text-xs'>linetype</th>}
                {currentGeoms.alpha === currentData.length ? null : <th className='text-xs'>alpha</th>}
                {currentGeoms.fill === currentData.length ? null : <th className='text-xs'>fill</th>}

                {currentGeoms.weight === currentData.length ? null : <th className='text-xs'>weight</th>}
                {currentGeoms.shape === currentData.length ? null : <th className='text-xs'>shape</th>}
                {currentGeoms.stroke === currentData.length ? null : <th className='text-xs'>stroke</th>}
                {currentGeoms.width === currentData.length ? null : <th className='text-xs'>width</th>}
                {currentGeoms.height === currentData.length ? null : <th className='text-xs'>height</th>}
                {currentGeoms.angle === currentData.length ? null : <th className='text-xs'>angle</th>}
                {currentGeoms.radius === currentData.length ? null : <th className='text-xs'>radius</th>}
                {currentGeoms.hjust === currentData.length ? null : <th className='text-xs'>hjust</th>}
                {currentGeoms.vjust === currentData.length ? null : <th className='text-xs'>vjust</th>}
                {currentGeoms.label === currentData.length ? null : <th className='text-xs'>label</th>}
                {currentGeoms.family === currentData.length ? null : <th className='text-xs'>family</th>}
                {currentGeoms.fontface === currentData.length ? null : <th className='text-xs'>fontface</th>}
                {currentGeoms.lineheight === currentData.length ? null : <th className='text-xs'>lineheight</th>}
                {currentGeoms.group === currentData.length ? null : <th className='text-xs'>group</th>}
              </tr>

            </thead>
            <tbody>

            {currentData.map((g, i) => {

              return <tr key={i}>
                  <td className='text-left pr-3 off'>{g.geometric_object}</td>
                  {currentGeoms.x === currentData.length ? null : <td className='text-center'><hr /> {createCell(g.x)}</td>}
                  {currentGeoms.y === currentData.length ? null : <td className='text-center'><hr /> {createCell(g.y)}</td>}
                  {currentGeoms.xmin === currentData.length ? null : <td className='text-center'><hr /> {createCell(g.xmin)}</td>}
                  {currentGeoms.xmax === currentData.length ? null : <td className='text-center'><hr /> {createCell(g.xmax)}</td>}
                  {currentGeoms.ymin === currentData.length ? null : <td className='text-center'><hr /> {createCell(g.ymin)}</td>}
                  {currentGeoms.ymax === currentData.length ? null : <td className='text-center'><hr /> {createCell(g.ymax)}</td>}
                  {currentGeoms.xend === currentData.length ? null : <td className='text-center'><hr /> {createCell(g.xend)}</td>}
                  {currentGeoms.yend === currentData.length ? null : <td className='text-center'><hr /> {createCell(g.yend)}</td>}
                 
                  {currentGeoms.color === currentData.length ? null : <td className='text-center'><hr /> {createCell(g.color)}</td>}
                  {currentGeoms.size === currentData.length ? null : <td className='text-center'><hr /> {createCell(g.size)}</td>}
                  {currentGeoms.linetype === currentData.length ? null : <td className='text-center'><hr /> {createCell(g.linetype)}</td>}
                  {currentGeoms.alpha === currentData.length ? null : <td className='text-center'><hr /> {createCell(g.alpha)}</td>}
                  {currentGeoms.fill === currentData.length ? null : <td className='text-center'><hr /> {createCell(g.fill)}</td>}

                  {currentGeoms.weight === currentData.length ? null : <td className='text-center'><hr /> {createCell(g.weight)}</td>}
                  {currentGeoms.shape === currentData.length ? null : <td className='text-center'><hr /> {createCell(g.shape)}</td>}
                  {currentGeoms.stroke === currentData.length ? null : <td className='text-center'><hr /> {createCell(g.stroke)}</td>}
                  {currentGeoms.width === currentData.length ? null : <td className='text-center'><hr /> {createCell(g.width)}</td>}
                  {currentGeoms.height === currentData.length ? null : <td className='text-center'><hr /> {createCell(g.height)}</td>}
                  {currentGeoms.angle === currentData.length ? null : <td className='text-center'><hr /> {createCell(g.angle)}</td>}
                  {currentGeoms.radius === currentData.length ? null : <td className='text-center'><hr /> {createCell(g.radius)}</td>}
                  {currentGeoms.hjust === currentData.length ? null : <td className='text-center'><hr /> {createCell(g.hjust)}</td>}
                  {currentGeoms.vjust === currentData.length ? null : <td className='text-center'><hr /> {createCell(g.vjust)}</td>}
                  {currentGeoms.label === currentData.length ? null : <td className='text-center'><hr /> {createCell(g.label)}</td>}
                  {currentGeoms.family === currentData.length ? null : <td className='text-center'><hr /> {createCell(g.family)}</td>}
                  {currentGeoms.fontface === currentData.length ? null : <td className='text-center'><hr /> {createCell(g.fontface)}</td>}
                  {currentGeoms.lineheight === currentData.length ? null : <td className='text-center'><hr /> {createCell(g.lineheight)}</td>}
                  
                  {currentGeoms.group === currentData.length ? null : <td className='text-center'><hr /> {createCell(g.group)}</td>}
                </tr>
            })}
            </tbody>
          </Table>

        </InnerContainerTable>
      </Container>
     

    </Shell>
  )
}

export default Aesthetics


export const query = graphql`
  query {
    geoms: allAestheticsCsv {
      nodes {
        group
        color
        size
        linetype
        alpha
        fill
        x
        y
        xmin
        xmax
        ymin
        ymax
        yend
        xend
        weight
        shape
        stroke
        width
        height
        angle
        radius
        hjust
        vjust
        label
        family
        fontface
        lineheight
        geometric_object
      }
    }
  }
`